import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import NavBar from "../components/NavBar"
import Carousel from "../components/Carousel"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

import "../css/portfolio.scss"

const IndexEN = ({ pageContext, data, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "en-US",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | portfolio"
        description="A sample of NetBistrot's work"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="container mx-auto">
        <div className="mx-2 md:mx-0">
          <div className="h-10" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-x-4 gap-y-8">
            <div className="col-span-1" />
            <div className="col-span-10">
              <h1>Print design</h1>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-6">
            <div className="h-5" />
            <p>We also develop printed content.</p>
            <p>
              We are able to prepare files to be printed and send them to a
              printing service near you.
            </p>
          </div>
        </div>
        <div className="h-10" />
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <Carousel
              images={data.printFiles.edges}
              alt="Assessoria de Marketing Executivo"
            />
          </div>
        </div>
      </div>
      <div className="h-8" />
      <div className="text-center">
        <span className="text-2xl">
          Ready to work together?
          <br />
          <br />
        </span>
        <Link
          className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
          to="/en/lets-work-together/?service=graphic-design"
        >
          let's start a graphic design project
        </Link>
      </div>
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default IndexEN

export const query = graphql`
  {
    printFiles: allFile(
      filter: { relativePath: { regex: "/printed/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
